import React from 'react';

const ProductLoadingRow = () => {
    return (
        <tr className="">
          <td className="text-left whitespace-nowrap w-8/12">
              <div className="space-x-1 animate-pulse">
                  <div className="h-2 w-8/12 bg-gray-300 rounded-md"></div>
              </div>
          </td>
          <td className="px-3 py-3 w-2/12 animate-pulse">
              <div className="h-2 w-full rounded-md bg-gray-300"></div>
          </td>
          <td className="px-3 py-3 w-2/12 animate-pulse">
            <div className="h-2 w-full rounded-md bg-gray-300"></div>
          </td>
        </tr>
      );
}

export default ProductLoadingRow;