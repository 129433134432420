import { combineEpics, ofType } from "redux-observable";
import { acceptBookingError, acceptBookingSuccess, acceptBookingWithPaymentVerification, acceptBookingWithPaymentVerificationError, acceptBookingWithPaymentVerificationSuccess, ACCEPT_BOOKING, ACCEPT_BOOKING_WITH_PAYMENT_VERIFICATION, declineBookingError, declineBookingSuccess, DECLINE_BOOKING, loadBookingError, loadBookingSuccess, LOAD_BOOKING } from "../actions/booking-actions";
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { CHECK_BOOKING } from "../actions/booking-actions";
import { BASE_URL } from "../variables";

const domain = BASE_URL;
// const webservice = "rdl-booking-manager-ws/ws/bookings/"

// const baseURI = domain + webservice;
const baseURI = domain;

const fetchBookingsEpic = action$ => action$.pipe(
    ofType(LOAD_BOOKING),
    mergeMap(action =>
        ajax.getJSON(baseURI + "load/uuid/" + action.payload.uuid + "/" + action.payload.auth).pipe(
            map(response => loadBookingSuccess(response)),
            catchError(error => of(loadBookingError(error)))
        )
    )
);

const acceptBookingsEpic = action$ => action$.pipe(
    ofType(ACCEPT_BOOKING),
    mergeMap(action =>
        ajax.post(baseURI + action.payload.id + "/onAccountTrigger/" + action.payload.auth).pipe(
            map(() => acceptBookingSuccess()),
            catchError(error => of(acceptBookingError(error))),
        )
    )
);

const checkBookingEpic = action$ => action$.pipe(
    ofType(CHECK_BOOKING),
    mergeMap(action => 
        ajax.getJSON(baseURI + action.payload.uuid + "/session/check/" + action.payload.auth).pipe(
            map(response => loadBookingSuccess(response)),
            catchError(error => of (loadBookingError(error)))
        )
    )
)

const declineBookingEpic = action$ => action$.pipe(
    ofType(DECLINE_BOOKING),
    mergeMap(action => 
        ajax.post(baseURI + action.payload.id + "/declined/" + action.payload.auth).pipe(
            map(response => declineBookingSuccess(response)),
            catchError(error => of(declineBookingError(error))),
        )
    )
)

const acceptBookingWithVerifyPayment = action$ => action$.pipe(
    ofType(ACCEPT_BOOKING_WITH_PAYMENT_VERIFICATION),
    mergeMap(action => 
        ajax.post(baseURI + action.payload.id + "/accept/verify/" + action.payload.auth).pipe(
            map(response => acceptBookingWithPaymentVerificationSuccess(response)),
            catchError(error => of(acceptBookingWithPaymentVerificationError(error)))
        )
    )
)

export default combineEpics(fetchBookingsEpic, acceptBookingsEpic, checkBookingEpic, declineBookingEpic, acceptBookingWithVerifyPayment);