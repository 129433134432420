import React from 'react'

const ClientLoading = () => {
    return (
        <div className="w-full pt-16">
            <div className="rounded-md w-10/12 shadow h-60 mx-auto p-6">
                <div className="animate-pulse">
                    <div className="w-6/12 h-2 rounded-md bg-gray-200"></div>
                    <div className="w-3/12 h-1.5 rounded-md bg-gray-200 mt-3"></div>
                    <div className="w-3/12 h-1.5 rounded-md bg-gray-200 mt-2"></div>
                    <div className="w-3/12 h-1.5 rounded-md bg-gray-200 mt-2 mb-1"></div>
                </div>
                <div className="border-b border-gray-400 mb-4 pb-2"></div>
                <div className="flex mx-auto">
                    <div className="inline w-1/2">
                        <div className="text-xs text-gray-500 animate-pulse">
                            <div className="w-8/12 h-2 rounded-md bg-gray-200 mt-2"></div>
                            <div className="w-5/12 h-1.5 rounded-md bg-gray-200 mt-3"></div>
                            <div className="w-5/12 h-1.5 rounded-md bg-gray-200 mt-2"></div>
                            <div className="w-5/12 h-1.5 rounded-md bg-gray-200 mt-2"></div>
                            <div className="w-5/12 h-1.5 rounded-md bg-gray-200 mt-2"></div>
                        </div>
                    </div>
                    <div className="inline w-1/2">
                        <div className="text-xs text-gray-500 animate-pulse">
                            <div className="w-8/12 h-2 rounded-md bg-gray-200 mt-2"></div>
                            <div className="w-5/12 h-1.5 rounded-md bg-gray-200 mt-3"></div>
                            <div className="w-5/12 h-1.5 rounded-md bg-gray-200 mt-2"></div>
                            <div className="w-5/12 h-1.5 rounded-md bg-gray-200 mt-2"></div>
                            <div className="w-5/12 h-1.5 rounded-md bg-gray-200 mt-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientLoading;