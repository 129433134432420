import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { acceptBooking } from '../../actions/booking-actions';
import { useParams } from 'react-router';

const ConfirmationDialogue = ({closeCallback}) => {

    const booking = useSelector(state => {
        return state.bookingReducer.booking;
    })

    const dispatch = useDispatch();

    let { auth } = useParams();

    const handleConfirm = () => {
        dispatch(acceptBooking({id: booking.id, auth: auth}));
    }

    return (
        <div className="w-96 h-16">
            <div className="text-center">Confirm your acceptance of the booking</div>
            <div className="w-48 mx-auto">
                <button className="btn-blue btn-blue-active inline" onClick={handleConfirm}>Confirm</button>
                <button className="btn-blue btn-blue-active inline" onClick={closeCallback}>Close</button>
            </div>
        </div>
    )
}

export default ConfirmationDialogue;