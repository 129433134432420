// import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { useParams } from 'react-router';
import { CREDIT_CARD } from '../../variables';
import VerifyPayment from './verify-payment';
import WindcavePayment from './windcave-payment';

const CashSaleDialogue = ({ closeCallback }) => {

    const creditCardPayment = useSelector((state) => {
        return state.bookingReducer.booking.paymentType === CREDIT_CARD;
    })

    return (
        <div className="w-80">
            { creditCardPayment ? <WindcavePayment></WindcavePayment> : <VerifyPayment closeCallback={closeCallback}></VerifyPayment>}
        </div>
    )


}

export default CashSaleDialogue;