import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { BASE_URL } from "../../variables";

const WindcavePayment = () => {
  let { auth } = useParams();

  const url = BASE_URL;
  const ntfyProxy = "https://bookings.rubbishdirect.co.nz/bookings/";
  const webAppUrl = "https://bookings.rubbishdirect.co.nz/";
  const proxy = "https://bookings.rubbishdirect.co.nz/windcave/";
  //   const ntfyProxy = "http://localhost:8880/RDLWebService/ws/bookings/";
  //   const webAppUrl = "http://localhost:3000/";
  //   const proxy = "http://localhost:8880/RDLWebService/ws/windcave/";
  //   http://localhost:8880/RDLWebService/ws/bookings/

  const bookingId = useSelector((state) => {
    return state.bookingReducer.booking.id;
  });

  const bookingUUID = useSelector((state) => {
    return state.bookingReducer.booking.uniqueID;
  });

  const priceInclGst = useSelector((state) => {
    return state.bookingReducer.booking.priceInclGst;
  });

  const [settingUp, setSettingUp] = useState(true);

  const [redirect, setRedirect] = useState("");

  const [setupMessage, setSetupMessage] = useState("");

  const [error, setError] = useState(false);

  useEffect(() => {
    createWindcaveSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createWindcaveSession = () => {
    const payload = JSON.stringify({
      type: "purchase",
      amount: priceInclGst,
      currency: "NZD",
      merchantReference: "BookingID" + bookingId,
      storeCard: false,
      storeCardIndicator: "single",
      callbackUrls: {
        approved: `${webAppUrl}approved/${auth}/${bookingUUID}`,
        declined: `${webAppUrl}declined/${auth}/${bookingUUID}`,
        cancelled: `${webAppUrl}cancelled/${auth}/${bookingUUID}`,
      },
      notificationUrl: ntfyProxy + bookingUUID + "/session/update",
    });
    setSetupMessage("Creating session");

    fetch(url + "windcave/auth/token/" + auth)
      .then((response) => response.json())
      .then((token) => {
        fetch(proxy + "v1/sessions", {
        // fetch("https://sec.windcave.com/api/v1/sessions", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token.message}`,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Authorization, X-Requested-With",
          },
          body: payload,
        })
          .then((response) => {
            if (response.status !== 202) {
              throw new Error("Not authorised to create session");
            }
            return response.json();
          })
          .then((json) => {
            var windcaveSessionId = json["id"];
            var bookingUrl =
              url +
              bookingId +
              "/update/session/" +
              windcaveSessionId +
              "/" +
              auth;
            fetch(bookingUrl, {
              method: "POST",
            }).then((response) => {
              if (response.status === 200) {
                var links = json["links"];
                for (var i = 0; i < links.length; i++) {
                  if (links[i].method === "REDIRECT") {
                    var redirect = links[i].href.replace(
                      "bookings.rubbishdirect.co.nz",
                      "sec.windcave.com"
                    );
                    setRedirect(redirect);
                  }
                }
              } else {
                // throw new Error("Error occured registering session");
                setSetupMessage("Error occured registering session");
                setError(true);
                setSettingUp(false);
              }
              setSettingUp(false);
            });
          })
          .catch((error) => {
            console.log(error);
            setSetupMessage(error.message);
            setError(true);
            setSettingUp(false);
          });
      })
      .catch((error) => {
        setSetupMessage(error.message);
        setError(true);
        setSettingUp(false);
      });
  };

  const goToPayment = () => {
    window.location.replace(redirect);
  };

  return (
    <div className="w-80">
      {error ? (
        <div className="text-center text-red-600">
          <div className="px-4 py-2 m-2 text-sm">{setupMessage}</div>
        </div>
      ) : (
        <div>
          <div className="w-10 mx-auto m-2">
            {settingUp ? (
              <svg
                className="animate-spin h-10 w-10 text-blue-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              <svg
                className="h-10 w-10"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#2563EB"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            )}
          </div>
          <div className=" h-10 w-3/5 mx-auto text-center">
            {settingUp ? (
              <div className="px-4 py-2 m-2 text-sm">{setupMessage}</div>
            ) : (
              <button
                className="btn-blue btn-blue-active inline"
                onClick={goToPayment}
              >
                Proceed to payment
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default WindcavePayment;
