import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { checkBooking } from '../actions/booking-actions';

const TransactionCompletionPage = () => {

    let { auth, uuid } = useParams();

    const history = useHistory();

    const [confirmationMessage, setConfirmationMessage] = useState("")

    const dispatch = useDispatch();

    const booking = useSelector((state) => {
        return state.bookingReducer.booking;
    })
    
    const loading = useSelector((state) => {
        return state.bookingReducer.loading;
    })

    const [dispatched, setDispatched] = useState(true);

    const [checkingBooking, setCheckingBooking] = useState(false);

    useEffect(() => {
        setCheckingBooking(true);
        dispatch(checkBooking({uuid: uuid, auth: auth}));
        setDispatched(true);
    }, [dispatch, uuid])

    const returnToBooking = useCallback(() => {
        history.push(`/booking/${auth}/${uuid}`);
    }, [history, uuid])

    const checkForBookingStatus = useCallback(() => {
        // console.log("Check booking status called");
        //Load booking from server and check if it has a windcave session
        if (booking.windcaveSessionId == null) {
            returnToBooking();
        } else {
            if (booking.paid) {
                setConfirmationMessage("Transaction approved and booking has been paid")
            } else {
                //if booking doesnt have a txn id, then return it to the booking page
                if (booking.bookingTxnId == null) {
                    setConfirmationMessage("Payment session incomplete return to booking to retry")
                } else {
                    setConfirmationMessage("Transaction was declined or cancelled return to booking to try again");
                }
            }
            setCheckingBooking(false);
        }
    }, [returnToBooking, booking])

    useEffect(() => {
        if (dispatched) {
            if (loading === false && booking.id != null) {
                checkForBookingStatus();
                setDispatched(false);
            }
        }
        
    }, [booking, checkForBookingStatus, dispatched, loading])


    return(
        <div className="w-screen h-screen bg-gray-100 pt-40">
            <div>
                <div className="rounded-md w-4/12 shadow-md h-60 mx-auto p-6 bg-white">
                    {
                        checkingBooking ? 
                        <div className="w-10 h-full mx-auto flex content-center justify-center">
                            <svg className="my-auto animate-spin h-10 w-10 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg> 
                        </div> :
                        <div>
                            <div className="text-center px-3 pt-12 pb-3">
                                {confirmationMessage}
                            </div>
                            <div className="w-full text-center">
                                <button className="btn-blue btn-blue active h-10" onClick={returnToBooking}>Return to booking</button>
                            </div>
                        </div> 
                    }                    
                </div>
            </div>
        </div>
    )
}

export default TransactionCompletionPage;