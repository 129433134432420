import React from 'react';
import { useSelector } from 'react-redux';

const CasualConditions = () => {

    const surcharge = useSelector(state => {
        return state.bookingReducer.booking.surcharge
    })


    return (
        <div className="px-6 py-6 w-96">
            <div className="text-gray-800 font-medium">
                Note
            </div>
            <p className="text-gray-700 text-sm">
                Please ensure the bins are placed in the designated collection point
                the evening before the removal/exchange date. See bin placements for details.
                A return trip will incur a surchage of ${surcharge}+gst.
            </p>
            <p>
                <br/>
            </p>
            <p className="text-gray-700 text-sm">
                To ensure your booking is organised in a timely manner, please accept the booking
                before 12pm the day prior to the first scheduled delivery date.
            </p>
        </div>
    )
}

export default CasualConditions;