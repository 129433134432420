import React from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from '../../actions/modal-actions';

const DeliveryMarker = () => {

    const dispatch = useDispatch();

    const viewBins = () => {
        dispatch(openModal())
    }

    return (
        <div className="flex flex-col items-center">
            <div className="bg-white w-32 transform -translate-y-20 rounded-md shadow-md px-3 py-2 text-center" onClick={viewBins}>
                View bin placements and instructions
            </div>
            <div className="h-8 w-8 absolute transform -translate-y-4 text-red-600">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                </svg>
            </div>
        </div>
    )
}

export default DeliveryMarker;