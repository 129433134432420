export const LOAD_BOOKING = "LOAD_BOOKING";
export const LOAD_BOOKING_SUCCESS = "LOAD_BOOKING_SUCCESS";
export const LOAD_BOOKING_ERROR = "LOAD_BOOKING_ERROR";
export const ACCEPT_BOOKING = "ACCEPT_BOOKING";
export const ACCEPT_BOOKING_SUCCESS = "ACCEPT_BOOKING_SUCCESS";
export const ACCEPT_BOOKING_ERROR = "ACCEPT_BOOKING_ERROR";
export const DECLINE_BOOKING = "DECLINE_BOOKING";
export const DECLINE_BOOKING_SUCCESS = "DECLINE_BOOKING_SUCCESS";
export const DECLINE_BOOKING_ERROR = "DECLINE_BOOKING_ERROR";
export const CHECK_BOOKING = "CHECK_BOOKING";
export const ACCEPT_BOOKING_WITH_PAYMENT_VERIFICATION = "ACCEPT_BOOKING_WITH_PAYMENT_VERIFICATION"
export const ACCEPT_BOOKING_WITH_PAYMENT_VERIFICATION_SUCCESS = "ACCEPT_BOOKING_WITH_PAYMENT_VERIFICATION_SUCCESS"
export const ACCEPT_BOOKING_WITH_PAYMENT_VERIFICATION_ERROR = "ACCEPT_BOOKING_WITH_PAYMENT_VERIFICATION_ERROR"

export function loadBooking(payload) {
    return { type: LOAD_BOOKING, payload: payload }
}

export function loadBookingSuccess(payload) {
    return { type: LOAD_BOOKING_SUCCESS, payload: payload }
}

export function loadBookingError(payload) {
    return { type: LOAD_BOOKING_ERROR, payload: payload, error: true }
}

export function acceptBooking(payload) {
    return { type: ACCEPT_BOOKING, payload: payload }
}

export function acceptBookingSuccess(payload) {
    return { type: ACCEPT_BOOKING_SUCCESS, payload: payload}
}

export function acceptBookingError(payload) {
    return { type: ACCEPT_BOOKING_ERROR, payload: payload, error: true }
}

export function checkBooking(payload) {
    return { type: CHECK_BOOKING, payload: payload}
}

export function declineBooking(payload) {
    return {type: DECLINE_BOOKING, payload: payload}
}

export function declineBookingSuccess(payload) {
    return { type: DECLINE_BOOKING_SUCCESS, payload: payload}
}

export function declineBookingError(payload) {
    return { type: DECLINE_BOOKING_ERROR, payload: payload, error: true}
}

export function acceptBookingWithPaymentVerification(payload) {
    return { type: ACCEPT_BOOKING_WITH_PAYMENT_VERIFICATION, payload: payload}
}

export function acceptBookingWithPaymentVerificationSuccess(payload) {
    return { type: ACCEPT_BOOKING_WITH_PAYMENT_VERIFICATION_SUCCESS, payload: payload }
}

export function acceptBookingWithPaymentVerificationError(payload) {
    return { type: ACCEPT_BOOKING_WITH_PAYMENT_VERIFICATION_ERROR, payload: payload, error: true }
}