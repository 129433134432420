import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Client from './client';
import DeliveryDetails from './map/delivery-details';
import ClientLoading from './skeletons/client-loading';
import Instructions from './instructions';
import { closeModal } from '../actions/modal-actions';
import Modal from './modal';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../variables';

const ClientPanel = () => {

    let { auth } = useParams();

    const loading = useSelector((state) => {
        return state.bookingReducer.loading;
    })

    const show = useSelector((state) => {
        return state.modalReducer.show;
    })

    const documentId = useSelector((state) => {
        return state.bookingReducer.booking.documentId;
    })

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(closeModal());
    }

    return (
        <div className="w-3/5 h-screen flex">
            <div className="w-1/12">
                <img className="ml-8 pt-6" src={process.env.PUBLIC_URL + '/logo-192x192.png'} alt="Rubbish Direct Logo" />
            </div>
            <div className="w-11/12">
                {loading ? <ClientLoading></ClientLoading> : <Client></Client>}
                <DeliveryDetails></DeliveryDetails>
                <Modal
                    height="h-screen"
                    width="w-3/5"
                    show={show}
                    closeModal={() => handleClose()}
                >
                    { documentId !== "" ? <embed src={`https://bookings.rubbishdirect.co.nz/documents/DocumentManager?a=get-file&fid=${documentId}&uz=${auth}`}
                        height="600"
                        width="100%"
                        className="mx-auto"
                    ></embed> : null
                    }
                    
                    <div className="border-t">
                        <Instructions></Instructions>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default ClientPanel;