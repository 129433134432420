import React, { useEffect, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';

const Modal = ({ height, width, show, closeModal, children }) => {

    const [showModal, setShowModal] = useState(show);

    const node = useRef();

    const attemptToCloseModal = event => {
        if (node.current.contains(event.target)) {
        return;
        } else {
            closeModal();
        }
    }

    useEffect(() => {
        setShowModal(show);
    }, [show])

    useEffect(() => {
        document.addEventListener("checkModalMouseDown", attemptToCloseModal);

        return () => {
            document.removeEventListener("checkModalMouseDown", attemptToCloseModal);
        }
    }, [])

    return (
        <Transition
            show={showModal}
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div className={`fixed ${height} ${width} top-0 left-0 flex items-center justify-center`}>
                <div className="absolute top-0 right-0 px-6 py-3 cursor-pointer" onClick={closeModal}>
                    <div className="h-6 w-6 text-white hover:text-gray-600">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </div>
                </div>
                <div className="absoluate w-full h-full bg-gray-900 bg-opacity-50 flex flex-col justify-center" onClick={attemptToCloseModal}>
                    <div className="h-auto w-max bg-white mx-auto rounded-md overflow-hidden" ref={node}>
                        {children}
                    </div>
                </div>
            </div>
        </Transition>
    )
}

export default Modal;