import { CLOSE_MODAL, OPEN_MODAL } from "../actions/modal-actions";

const initialState = {
    show: false
}

export function modalReducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_MODAL:
            return {
                show: true
            }
        case CLOSE_MODAL: 
            return {
                show: false
            }
        default:
            return state;
    }
}