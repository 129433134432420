import React from 'react'

const InorganicConditions = () => {
    return(
        <div className="w-160">
        <div className="w-5/6 mx-auto py-12">
            <div className="text-gray-800 font-medium">
                Conditions of Inorganic Collection
                </div>
            <div className="text-gray-700 text-sm">
                <ul className="list-outside list-disc">
                    <li>No Car / Vehicle Tyres.</li>
                    <li>No Concrete.</li>
                    <li>No Soil.</li>
                    <li>No Polystyrene.</li>
                    <li>If you are unsure, please ask our Customer Service Team.</li>
                    <li>Rubbish Direct will not be responsible for damage to grass/soil if they need
                        to drive over it to remove a inorganics. Note that rainwater can make
                        it easier for the truck wheels to damage the grass/soil.
                    </li>
                </ul>
            </div>
            <div className="text-gray-800 font-medium pt-6">
                No hazardous waste
            </div>
            <div className="text-gray-700 text-sm">
                <ul className="list-outside list-disc">
                    <li>Flammable</li>
                    <li>Eco-toxic</li>
                    <li>Toxic</li>
                    <li>Infectious</li>
                    <li>Explosive</li>
                    <li>Radioactive</li>
                    <li>Corrosive</li>
                </ul>
            </div>
            <div>
                <p>
                    <br/>
                </p>
                <p className="text-gray-700 text-sm">
                    To ensure your booking is organised in a timely manner, please accept the booking
                    before 12pm the day prior to the first collection.
                    <br/>
                    Please ensure all items for removal are placed in the agreed location prior to the removal date. These will need to be clearly set aside separately from any other items.
                    <br/>
                    Rubbish Direct will <u>not</u> accept liability for the removal of any incorrect items if these are left in the same area.
                </p>
            </div>
        </div>
    </div>
    )
}

export default InorganicConditions;