import React from 'react';
import { DateTime } from 'luxon';

const ProductInorganic = ({ job }) => {

    const removalDate = DateTime.fromISO(job.dateOfFirstAction).toFormat("dd LLL yyyy");

    return (
        <tr>
            <td className="text-left py-3 whitespace-nowrap w-8/12">
                <div className="space-x-1">
                    <div className="inline text-gray-700">
                        Inorganic Collection
                </div>
                    <div className="text-sm font-light text-gray-500">
                        Removal: {removalDate}
                    </div>
                </div>
            </td>
            <td className="px-3 w-2/12 text-gray-700">
                ${job.price}
                <div className="invisible">placeholder</div>
            </td>
            <td className="px-3 w-2/12 text-gray-700">
                ${job.price}
                <div className="invisible">placeholder</div>
            </td>
        </tr>
    )
}

export default ProductInorganic;