import React from 'react';

const ExpiredDialogue = () => {
    return (
        <div className="w-96">
            The quote for the booking has expired. Please call our Customer Service Team on 0800 36 77 35
            to rearrange the booking.
        </div>
    )
}

export default ExpiredDialogue;