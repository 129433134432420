import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import DeliveryMarker from './delivery-marker';
import { useSelector } from 'react-redux';

const DeliveryDetails = () => {

    const bookingLat = useSelector(state => {
        return state.bookingReducer.booking.deliveryLat
    })

    const bookingLng = useSelector(state => {
        return state.bookingReducer.booking.deliveryLng
    })

    const defaultCenter = {lat:-36.887, lng: 174.664};

    const [center, setCenter] = useState({lat:-36.887, lng: 174.664});
    const zoom = 16;

    useEffect(() => {
        setCenter({lat: bookingLat, lng: bookingLng});
    }, [bookingLat, bookingLng])

    return (
        <div className="h-1/2 w-10/12 bg-gray-100 mx-auto my-6 rounded-md overflow-hidden shadow-md">
            <GoogleMapReact
                bootstrapURLKeys = {{key: "AIzaSyAk7plKqYI9UFCY6kljl-K6GKMKkxYzDaQ"}}
                defaultCenter = {defaultCenter}
                defaultZoom = {zoom}
                center = {center}
            >
                <DeliveryMarker
                    lat={bookingLat}
                    lng={bookingLng}
                >
                </DeliveryMarker>
            </GoogleMapReact>            
        </div>
    );
}

export default DeliveryDetails;