import React from 'react';
import { useSelector } from 'react-redux';

const SkipBinConditions = () => {

    const surcharge = useSelector(state => {
        return state.bookingReducer.booking.surcharge
    })


    return (
        <div className="w-160">
            <div className="w-5/6 mx-auto py-12">
                <div className="text-gray-800 font-medium">
                    Conditions of skip bin hire
                    </div>
                <div className="text-gray-700 text-sm">
                    <ul className="list-outside list-disc">
                        <li>Bin must not be filled over the top of the skip.</li>
                        <li>No Car / Vehicle Tyres.</li>
                        <li>No Concrete.</li>
                        <li>No Soil.</li>
                        <li>No Polystyrene.</li>
                        <li>If you are unsure, please ask our Customer Service Team.</li>
                        <li>Excess weight fee incurred for skip bins that exceed 1.35 tonnes - $0.15+gst per kg</li>
                        <li>Additional days if required - $10.00+gst per day</li>
                        <li>Rubbish Direct will not be responsible for damage to grass/soil if they need
                            to drive over it to deliver or remove a skip bin. Note that rainwater can make
                            it easier for the truck wheels to damage the grass/soil. So, keep this in mind when deciding
                            where you want the skip bin placed.
                        </li>
                    </ul>
                </div>
                <div className="text-gray-800 font-medium pt-6">
                    No hazardous waste
                </div>
                <div className="text-gray-700 text-sm">
                    <ul className="list-outside list-disc">
                        <li>Flammable</li>
                        <li>Eco-toxic</li>
                        <li>Toxic</li>
                        <li>Infectious</li>
                        <li>Explosive</li>
                        <li>Radioactive</li>
                        <li>Corrosive</li>
                    </ul>
                </div>
                <div className="text-gray-800 font-medium pt-6">
                    Note
                </div>
                <div className="text-gray-700 text-sm">
                    <p>
                        To extend hire, please contact Customer Service on 0800 367 735. Changes to bin removal/exchange date
                        need to be requested by 12pm the day prior to your scheduled removal/exchange date. Failure to do
                        so will incur a return trip surchage of ${surcharge}+gst
                    </p>
                </div>
                <div>
                    <p>
                        <br/>
                    </p>
                    <p className="text-gray-700 text-sm">
                        To ensure your booking is organised in a timely manner, please accept the booking
                        before 12pm the day prior to the first scheduled delivery date.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default SkipBinConditions;