import React from 'react';
import { DateTime } from 'luxon';

const ProductCasual = ({ job }) => {

    const deliveryDate = DateTime.fromISO(job.dateOfFirstAction).toFormat("dd LLL yyyy");
    const removalDate = DateTime.fromISO(job.dateOfSecondAction).toFormat("dd LLL yyyy");

    return (
        <tr>
            <td className="text-left py-3 whitespace-nowrap w-8/12">
                <div className="space-x-1">
                    <div className="inline text-gray-700">
                        {job.binDetails.name}
                    </div>
                    <div className="inline text-sm font-light text-gray-500">
                        x{job.binDetails.quantity}
                    </div>
                    <div className="text-sm font-light text-gray-500">
                        Delivery: {deliveryDate}
                    </div>
                    {job.dateOfSecondAction ? 
                    <div className="text-sm font-light text-gray-500">
                        Removal: {removalDate}
                    </div> : null
                    }

                </div>
            </td>
            <td className="px-3 w-2/12 text-gray-700">
                ${job.binDetails.price}
                <div className="invisible">placeholder</div>
                { job.dateOfSecondAction ? <div className="invisible">placeholder</div> : null}
            </td>
            <td className="px-3 w-2/12 text-gray-700">
                ${job.price}
                <div className="invisible">placeholder</div>
                { job.dateOfSecondAction ? <div className="invisible">placeholder</div> : null}
            </td>
        </tr>
    )
}

export default ProductCasual;