import React from 'react';
import { useSelector } from 'react-redux';

const Client = () => {

    const client = useSelector(state => {
        return state.bookingReducer.booking.clientCompany
    })

    const bookingContactName = useSelector(state => {
        return state.bookingReducer.booking.contactName
    })

    const bookingContactNumber = useSelector(state => {
        return state.bookingReducer.booking.contactNumber
    })

    const bookingContactEmail = useSelector(state => {
        return state.bookingReducer.booking.contactEmail
    })

    const bookingAddress = useSelector(state => {
        return state.bookingReducer.booking.address.split(',')
    })

    const bookingDeliveryAddress = useSelector(state => {
        return state.bookingReducer.booking.deliveryAddress.split(',')
    })
    
    return (
        <div className="w-full pt-16">
            <div className="rounded-md w-10/12 shadow-md h-60 mx-auto p-6">
                <div className="mb-1">
                    {/* {client.clientCompany} */}
                    {client}
                </div>
                <div className="text-xs text-gray-500">
                    <div>
                        {bookingContactName}
                    </div>
                    <div>
                        {bookingContactNumber}
                    </div>
                    <div>
                        {bookingContactEmail}
                    </div>
                </div>
                <div className="border-b border-gray-400 mb-4 pb-2"></div>
                <div className="flex mx-auto">
                    <div className="inline w-1/2">
                        Billing Address
                        <div className="text-xs text-gray-500">
                            {bookingAddress.map((addressLine, i) => (
                                <div key={i}>    
                                    {addressLine}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="inline w-1/2">
                        Delivery Address
                        <div className="text-xs text-gray-500">
                            {bookingDeliveryAddress.map((addressLine, i) => (
                                <div key={i}>
                                {addressLine}
                                </div>
                            )) }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Client;