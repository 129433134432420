import { useSelector } from "react-redux";
import { ACCEPTED, AWAITING_ACTION, CANCELLED, DECLINED } from "../variables";


const QuoteAcceptanceControl = ({ acceptCallback, declineCallback }) => {

    const bookingStatus = useSelector((state) => {
        console.log(state.bookingReducer.booking.bookingStatus)
        return state.bookingReducer.booking.bookingStatus;
    })

    const paid = useSelector((state) => {
        return state.bookingReducer.booking.paid;
    })

    switch(bookingStatus) {
        case ACCEPTED:
            return (
                <div className="h-16 relative w-3/4 mx-auto pt-2">
                    <div className="text-center text-gray-700">
                        {paid ? <div>This booking has been paid</div> : <div>This booking has been accepted</div>}
                    </div>                   
                </div>
            )
        case CANCELLED:
            return (
                <div className="h-16 relative w-3/4 mx-auto pt-2">
                    <div className="text-center text-gray-700">
                        <div>This booking has been cancelled</div>
                    </div>
                </div>
            )
        // case DECLINED:
        //     return (
        //         <div className="h-16 relative w-3/4 mx-auto pt-2">
        //             <div className="text-center text-gray-700">
        //                 <div>This booking has been declined</div>
        //             </div>                   
        //         </div>
        //     )
        case AWAITING_ACTION:
        case DECLINED:
            return (
                <div className="h-16 relative w-3/4 mx-auto pt-2">
                    <div className="absolute right-0">
                        <button className="btn-blue btn-blue-active" onClick={acceptCallback}>
                            Accept
                            </button>
                        <button className="btn-blue btn-blue-active" onClick={declineCallback}>
                            Decline
                            </button>
                    </div>
                </div>
            )
        default:
            return (<div>error in fetched data</div>)

    }

    // return (
    //     <div className="h-16 relative w-3/4 mx-auto pt-2">
    //             {accepted || cancelled ?
    //                 <div className="text-center text-gray-700">
    //                     {cancelled ? <div>This booking has been cancelled</div> : <div>
    //                     {paid ? <div>This booking has been paid</div> : <div>This booking has been accepted</div>}</div>}
    //                 </div> :
    //                 <div className="absolute right-0">
    //                     <button className="btn-blue btn-blue-active" onClick={acceptCallback}>
    //                         Accept
    //                         </button>
    //                     <button className="btn-blue btn-blue-active" onClick={declineCallback}>
    //                         Decline
    //                         </button>
    //                 </div>
    //             }
    //         </div>
    // )
}

export default QuoteAcceptanceControl;