import React from 'react';

const ErrorDialogue = () => {
    return (
        <div className="w-96 h-16">
            <div className="text-red-600 flex flex-col items-center">
                <svg className="h-10 w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <div>
                    An error occurred
                </div>
            </div>
        </div>
    )
}

export default ErrorDialogue;