import React, { useEffect } from 'react';
import ClientPanel from './client-panel';
import QuotePanel from './quote-panel';
import ErrorPage from './error-page';
import { useDispatch, useSelector } from 'react-redux';
import { loadBooking } from '../actions/booking-actions';
import { useParams } from 'react-router-dom';

const Booking = () => {

    let { auth, uuid } = useParams();

    const loadingFailed = useSelector(state => {
        return state.bookingReducer.loadError;
    });

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadBooking({uuid: uuid, auth: auth}));
    }, [])

    return (
        <div>
            {loadingFailed ?
                <ErrorPage></ErrorPage> :
                <div className="flex flex-row">
                    <ClientPanel></ClientPanel>
                    <QuotePanel></QuotePanel>
                </div>
            }
        </div>
    )
}

export default Booking;