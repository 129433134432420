import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { loadBooking } from '../../actions/booking-actions';

const ConfirmationSuccess = ({closeCallback}) => {
    const { auth, uuid } = useParams();

    const dispatch = useDispatch();

    const handleClose = () => {
        closeCallback();
        dispatch(loadBooking({uuid: uuid, auth: auth}))
    }

    return (
        <div className="w-96 h-16">
            <div className="text-green-600 flex flex-col items-center">
                <div>
                    Confirmation success
                </div>
                <button className="btn-blue btn-blue-active inline" onClick={handleClose}>Close</button>
            </div>
        </div>
    )
}

export default ConfirmationSuccess;