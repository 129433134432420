import React from 'react';

const ErrorPage = () => {
    return (
        <div className="h-screen w-screen relative">
            <div className="w-1/2 pt-40 mx-auto text-center text-gray-700">
                Something went wrong :(
            </div>
            <div className="absolute bottom-0 w-full text-center pb-6 text-gray-400 text-sm">
                <span className="text-center">0800 36 77 35 | info@rubbishdirect.co.nz | www.rubbishdirect.co.nz</span>
            </div>
        </div>
    )
}

export default ErrorPage;