import React from 'react';
import { useSelector } from 'react-redux';

const Instructions = () => {

    const jobs = useSelector(state => { return state.bookingReducer.booking.futureJobs; });

    return (
        <div className="px-3 py-3 h-auto w-160">
            {jobs.map((job) => (
                <div className="py-1 flex space-x-2" key={job.id}>
                    { job.type === 1 ? <div className="text-gray-800 inline">{job.binDetails.name} instructions:</div> : null}
                    { job.type === 2 ? <div>Inorganic Colllection instructions:</div> : null}
                    { job.type === 3 ? <div>9M3 Skip Bin instructions: </div> : null}
                    { job.instructions ? <div className="text-gray-400 inline">{job.instructions}</div> : <div className="text-gray-400">N/A</div>}
                </div>
            ))}
        </div>
    )
}

export default Instructions;