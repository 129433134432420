// const PROD = "https://rdltr.com:8999/";
const PROD = "https://bookings.rubbishdirect.co.nz/bookings/";
const BETA = "https://beta.rdltr.com/rdl-booking-manager-ws/ws/bookings/";
const LOCALHOST = "http://localhost:8880/RDLWebService/ws/bookings/";

export const BASE_URL = PROD;

export const ON_ACCOUNT = "ON_ACCOUNT";
export const VERIFY_PAYMENT = "VERIFY_PAYMENT";
export const CREDIT_CARD = "CREDIT_CARD";

export const ACCEPTED = "ACCEPTED";
export const AWAITING_ACTION = "AWAITING_ACTION";
export const DECLINED = "DECLINED";
export const CANCELLED = "CANCELLED";
