import { ACCEPT_BOOKING, ACCEPT_BOOKING_ERROR, ACCEPT_BOOKING_SUCCESS, ACCEPT_BOOKING_WITH_PAYMENT_VERIFICATION, ACCEPT_BOOKING_WITH_PAYMENT_VERIFICATION_ERROR, ACCEPT_BOOKING_WITH_PAYMENT_VERIFICATION_SUCCESS, CHECK_BOOKING, DECLINE_BOOKING, DECLINE_BOOKING_ERROR, DECLINE_BOOKING_SUCCESS, LOAD_BOOKING, LOAD_BOOKING_ERROR, LOAD_BOOKING_SUCCESS } from '../actions/booking-actions';

const EMPTY_BOOKING = {address: "", deliveryAddress: "", client: {cashSalesCustomer: false}, futureJobs: []};

const initialState = {
    loading: true,
    booking: EMPTY_BOOKING,
    loadError: false,
    processingAccept: false,
    acceptSuccess: false,
    processingAcceptError: false,
    processingDecline: false,
    declineSuccess: false,
    processingDeclineError: false,
    processingAcceptWithVerification: false,
    processingAcceptWithVerificationSuccess: false,
    processingAcceptWithVerificationError: false,
}

export function bookingReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_BOOKING_SUCCESS:
            return {
                ...state,
                loading: false,
                booking: action.payload,
                loadError: false,
            }
        case LOAD_BOOKING:
            return {
                ...state,
                loading: true,
                loadBooking: true,
                loadError: false,
            }
        case LOAD_BOOKING_ERROR:
            return {
                ...state,
                loading: false,
                booking: EMPTY_BOOKING,
                loadError: true,
            }
        case ACCEPT_BOOKING:
            return {
                ...state,
                processingAccept: true
            }
        case ACCEPT_BOOKING_SUCCESS:
            return {
                ...state,
                acceptSuccess: true,
                processingAccept: false,
                processingAcceptError: false,
            }
        case ACCEPT_BOOKING_ERROR:
            return {
                ...state, 
                processingAcceptError: true,
            }
        case CHECK_BOOKING:
            return {
                ...initialState,
                loading: true,
                loadBooking: true,
                loadError: false,
            }
        case DECLINE_BOOKING:
            return {
                ...state,
                processingDecline: true,
            }
        case DECLINE_BOOKING_SUCCESS:
            return {
                ...state,
                processingDecline: false, 
                declineSuccess: true,
                processingDeclineError: false,
            }
        case DECLINE_BOOKING_ERROR:
            return {
                ...state,
                processingDeclineError: true,
                processingDecline: false,
                declineSuccess: false,
            }
        case ACCEPT_BOOKING_WITH_PAYMENT_VERIFICATION:
            return {
                ...state,
                processingAcceptWithVerification: true,
                processingAcceptWithVerificationSuccess: false,
                processingAcceptWithVerificationError: false,
            }
        case ACCEPT_BOOKING_WITH_PAYMENT_VERIFICATION_ERROR:
            return {
                ...state,
                processingAcceptWithVerification: false,
                processingAcceptWithVerificationSuccess: false,
                processingAcceptWithVerificationError: true,

            }
        case ACCEPT_BOOKING_WITH_PAYMENT_VERIFICATION_SUCCESS: 
            return {
                ...state,
                processingAcceptWithVerification: false,
                processingAcceptWithVerificationSuccess: true,
                processingAcceptWithVerificationError: false,
            }
        default:
            return state;
    }
}