import React from 'react';

const DeclineMessage = () => {
    return (
        <div className="p-6 text-center">
            If there are any issues with your booking please call our Customer Service Team on 
            0800 36 77 35
        </div>
    )
}

export default DeclineMessage;