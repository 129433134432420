import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import TransactionCompletionPage from './components/transaction-completion-page';
import Booking from './components/booking';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
        <Router>
            <Switch>
                <Route exact path="/"><App /></Route>
                <Route path="/booking/:auth/:uuid">
                <Booking></Booking>
                </Route>
                <Route path="/approved/:auth/:uuid">
                <TransactionCompletionPage></TransactionCompletionPage>
                </Route>
                <Route path="/declined/:auth/:uuid">
                <TransactionCompletionPage></TransactionCompletionPage>
                </Route>
                <Route path="/cancelled/:auth/:uuid">
                <TransactionCompletionPage></TransactionCompletionPage>
                </Route>
            </Switch>
        </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
