import React from "react";
import ProductCasual from "./product-casual";
import ProductInorganic from "./product-inorganic";
import ProductSkipBin from "./product-skip-bin";

const Product = ({ job }) => {

  const CASUAL = 1;
  const INORGANIC = 2;
  const SKIP_BIN = 3;

  switch(job.type) {
    case CASUAL:
      return <ProductCasual job={job}></ProductCasual>
    case INORGANIC:
      return <ProductInorganic job={job}></ProductInorganic>
    case SKIP_BIN:
      return <ProductSkipBin job={job}></ProductSkipBin>
    default:
      return <tr></tr>
  }
};

export default Product;
