import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Product from "./product/product";
import ProductLoadingRow from "./skeletons/product-loading-row";

const ProductList = () => {

  const futureJobs = useSelector((state) => {
    return state.bookingReducer.booking.futureJobs;
  })

  const loading = useSelector((state) => {
    return state.bookingReducer.loading;
  })

  const travelCost = useSelector((state) => {
    return state.bookingReducer.booking.travelCost;
  })

  const priceInclGst = useSelector((state) => {
    return state.bookingReducer.booking.priceInclGst;
  })

  const minimumChargeDifference = useSelector((state) => {
    return state.bookingReducer.booking.minimumChargeDifference;
  });

  const [totalJobCost, setJobCost] = useState(0);

  const [gstCost, setGstCost] = useState(0.0);

  const [totalPrice, setTotalPrice] = useState(0.0);

  useEffect(() => {
    updateTotalPrice();
  }, [futureJobs])

  const updateTotalPrice = () => {
    let price = 0;
    var i = 0;
    for (i = 0; i < futureJobs.length; i++) {
      price += futureJobs[i].price;
    }
    // price += minimumChargeDifference;
    setJobCost(price);
    
    let gst = priceInclGst - price - travelCost - minimumChargeDifference;
    setGstCost(gst.toFixed(2));
  }

  useEffect(() => {
    if (priceInclGst != null) {
      setTotalPrice(priceInclGst.toFixed(2))
    }
  },[priceInclGst])
  
  return (
    <div className="w-9/12 mx-auto">
      <table className="table-fixed">
        <thead>
          <tr>
            <td colSpan="100%" className="border-b border-gray-400 mb-3 pb-1"></td>
          </tr>
          <tr>
            <th scope="col" className="w-8/12"></th>
            <th
              scope="col"
              className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12"
            >
              Price
						</th>
            <th
              className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12"
            >
              Subtotal
						</th>
          </tr>
        </thead>
        {loading ?
          <tbody className="max-h-48 overflow-y-auto">
            <ProductLoadingRow></ProductLoadingRow>
            <ProductLoadingRow></ProductLoadingRow>
            <ProductLoadingRow></ProductLoadingRow>
            <tr>
              <td colSpan="100%" className="border-b border-gray-400"></td>
            </tr>
            <tr>
              <td colSpan="100%" className="h-2"></td>
            </tr>
            <ProductLoadingRow></ProductLoadingRow>
            <ProductLoadingRow></ProductLoadingRow>
            <tr>
              <td colSpan="100%" className="border-b border-gray-400"></td>
            </tr>
            <ProductLoadingRow></ProductLoadingRow>
          </tbody> :
          <tbody  className="h-32 overflow-y-auto">
            {futureJobs.map((job) => (
              <Product job={job} key={job.id}></Product>
            ))}
            <tr>
              <td colSpan="100%" className="border-b border-gray-400"></td>
            </tr>
            <tr>
              <td className="pt-5 pb-2 text-gray-700">
                Job Cost
						</td>
              <td>
              </td>
              <td className="px-3 py-2 text-gray-700">
                ${totalJobCost}
              </td>
            </tr>
            {
              minimumChargeDifference > 0 ? 
              <tr>
                <td  className="py-2 text-gray-700">
                  Minimum Charge Difference
                  <div className="pl-2 inline has-tooltip">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 inline transform -translate-y-px" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                    </svg>
                    <span className="tooltip rounded shadow-lg p-2 bg-gray-600 text-white text-xs -mt-20 -ml-24 w-72">
                      Bookings with Rubbish Direct have a minimum charge of $60+gst. If the Job Cost in your booking
                      is less than $60, a Minimum Charge Difference will be included.
                      </span>
                  </div>
                </td>
                <td>
                </td>
                <td className="px-3 py-2 text-gray-700">
                  ${minimumChargeDifference}
                </td>
              </tr> : null
            }
            <tr>
              <td className="py-2 text-gray-700">
                Travel Cost
						  </td>
              <td>
              </td>
              <td className="px-3 py-2 text-gray-700">
                ${travelCost}
              </td>
            </tr>
            <tr>
              <td colSpan="100%" className="border-b border-gray-400"></td>
            </tr>
            <tr>
              <td className="pt-5 py-2 text-gray-700">
                Total Cost
						  </td>
              <td>
              </td>
              <td className="px-3 py-2 text-gray-700">
                ${travelCost + totalJobCost + minimumChargeDifference}
              </td>
            </tr>
            <tr>
              <td className="py-2 text-gray-700">
                GST
						  </td>
              <td>
              </td>
              <td className="px-3 py-2 text-gray-700">
                ${gstCost}
              </td>
            </tr>
            <tr>
              <td colSpan="100%" className="border-b border-gray-400"></td>
            </tr>
            <tr>
              <td className="py-2 text-gray-700">
                Total Cost incl. GST (NZD)
						  </td>
              <td>
              </td>
              <td className="px-3 py-2 text-gray-700">
                ${totalPrice}
              </td>
            </tr>
          </tbody>
        }
      </table>
    </div>
  );
};

export default ProductList;
