import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { ON_ACCOUNT } from '../../variables';
import CashSaleDialogue from '../accept-dialogues/cash-sale-dialogue';
import ConfirmationDialogue from '../accept-dialogues/confirmation-dialogue';
import ConfirmationSuccess from '../accept-dialogues/confirmation-success';
import ErrorDialogue from '../accept-dialogues/error-dialogue';
import ExpiredDialogue from '../accept-dialogues/expired-dialogue';
import ProcessDialogue from '../accept-dialogues/process-dialogue';

const AcceptanceDialog = ({ closeCallback }) => {

    const expired = useSelector(state => {
        if (state.bookingReducer.booking.quoteExpiry) {
            var expiryDate = DateTime.fromISO(state.bookingReducer.booking.quoteExpiry);
            var now = DateTime.local();
            if (now >= expiryDate) {
                return true;
            } else {
                return false;
            }
        }
    })

    const cashSaleCustomer = useSelector(state => {
        if (state.bookingReducer.booking.paymentType !== ON_ACCOUNT) {
            return true;
        } else {
            return false;
        }
    })

    const processing = useSelector(state => { return state.bookingReducer.processingAccept })

    const acceptSuccess = useSelector(state => { return state.bookingReducer.acceptSuccess })

    const acceptError = useSelector(state => {return state.bookingReducer.processingAcceptError})

    if (expired) {
        return (
            <div className="p-10"><ExpiredDialogue></ExpiredDialogue></div>
        )
    }

    if (!cashSaleCustomer && acceptError) {
        return (
            <div className="p-10"><ErrorDialogue></ErrorDialogue></div>
        )
    }

    if (!cashSaleCustomer && acceptSuccess) {
        return (
            <div  className="p-10"><ConfirmationSuccess closeCallback={closeCallback}></ConfirmationSuccess></div>
        )
    }

    if (!cashSaleCustomer && processing) {
        return (
            <div  className="p-10"><ProcessDialogue></ProcessDialogue></div>
        )
    }

    return (
        <div className="p-10">
            { cashSaleCustomer ? <CashSaleDialogue closeCallback={closeCallback}></CashSaleDialogue> : <ConfirmationDialogue closeCallback={closeCallback}></ConfirmationDialogue>}
        </div>
    )
}

export default AcceptanceDialog