import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CasualConditions from './modal-content/casual-conditions';
import Modal from './modal';
import ProductList from './product-list';
import SkipBinConditions from './modal-content/skip-bin-conditions';
import DeclineMessage from './modal-content/decline-message';
import AcceptanceDialog from './modal-content/acceptance-dialog';
import { declineBooking } from '../actions/booking-actions';
import { useParams } from 'react-router';
import InorganicConditions from './modal-content/inorganic-conditions';
import { ACCEPTED } from '../variables';
import QuoteAcceptanceControl from './quote-acceptance-control';

const QuotePanel = () => {

    let { auth } = useParams();

    const purchaseOrder = useSelector((state) => {
        return state.bookingReducer.booking.po
    })

    const jobs = useSelector(state => {
        return state.bookingReducer.booking.futureJobs;
    })

    const paid = useSelector(state => {
        return state.bookingReducer.booking.paid;
    })

    const accepted = useSelector(state => {
        return state.bookingReducer.booking.bookingStatus === ACCEPTED;
    })

    // const cancelled = useSelector(state => {
    //     return state.bookingReducer.booking.bookingStatus === CANCELLED;
    // })

    const bookingId = useSelector(state => {
        return state.bookingReducer.booking.id;
    })

    const [showSkipBinConditions, setShowSkipBinConditions] = useState(false);

    const [showInorganicConditions, setShowInorganicConditions] = useState(false);

    const [showCasualConditions, setShowCasualConditions] = useState(false);

    const [containsSkipBinJob, setContainsSkipBinJob] = useState(false);

    const [containsInorganicJob, setContainsInorganicJob] = useState(false);

    const [containsCasualJob, setContainsCasualJob] = useState(false);

    const [skipBinConditionsAccepted, setSkipBinConditionsAccepted] = useState(false);

    const [casualConditionsAccepted, setCasualConditionsAccepted] = useState(false);

    const [inorganicConditionsAccepted, setInorganicConditionsAccepted] = useState(false);

    const [showAcceptModal, setShowAcceptModal] = useState(false);

    const [casualConditionsNotAccepted, setCasualConditionsNotAccepted] = useState(false);

    const [skipBinConditionsNotAccepted, setSkipBinConditionsNotAccepted] = useState(false);

    const [inorganicConditionsNotAccepted, setInorganicConditionsNotAccepted] = useState(false);

    const [showDeclineModal, setShowDeclineModal] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (jobs.some(job => job.type === 3)) {
            setContainsSkipBinJob(true);
        }
        if (jobs.some(job => job.type === 2)) {
            setContainsInorganicJob(true);
        }
        if (jobs.some(job => job.type === 1)) {
            setContainsCasualJob(true);
        }
        
    }, [jobs])

    useEffect(() => {
        if (paid) {
            setSkipBinConditionsAccepted(true);
            setCasualConditionsAccepted(true);
            setInorganicConditionsAccepted(true);
        }
    }, [paid])

    useEffect(() => {
        if (accepted) {
            setCasualConditionsAccepted(true);
            setSkipBinConditionsAccepted(true);
            setInorganicConditionsAccepted(true);
        }
    }, [accepted])

    const toggleSkipBinCheck = () => {
        setSkipBinConditionsAccepted(!skipBinConditionsAccepted);
        setSkipBinConditionsNotAccepted(false);
    }

    const toggleCasualCheck = () => {
        setCasualConditionsAccepted(!casualConditionsAccepted);
        setCasualConditionsNotAccepted(false);
    }

    const toggleInorganicCheck = () => {
        setInorganicConditionsAccepted(!inorganicConditionsAccepted);
        setInorganicConditionsNotAccepted(false);
    }

    const handleAccept = () => {
        let allConditionsAccepted = true;
        if (containsCasualJob) {
            if (!casualConditionsAccepted) {
                allConditionsAccepted = false
                setCasualConditionsNotAccepted(true);
            }
        }
        if (containsSkipBinJob) {
            if (!skipBinConditionsAccepted) {
                allConditionsAccepted = false;
                setSkipBinConditionsNotAccepted(true);
            }
        }
        if (containsInorganicJob) {
            if (!inorganicConditionsAccepted) {
                allConditionsAccepted = false;
                setInorganicConditionsNotAccepted(true);
            }
        }
        if (allConditionsAccepted) {
            setShowAcceptModal(true);
        }
    }

    const handleDecline = () => {
        dispatch(declineBooking({id: bookingId, auth: auth}));
        setShowDeclineModal(true);
    }

    return (
        <div className="w-2/5 h-full bg-gray-100 fixed right-0 overflow-y-auto">
            <div className="h-1/6"></div>
            <div className="mx-auto w-3/4 text-gray-700">
                <div> 
                    Booking Reference: {bookingId}
                </div>
                <div>
                    {purchaseOrder !== "" ? <div>Purchase Order: {purchaseOrder}</div> : null}
                </div>
            </div>
            <ProductList></ProductList>
            { containsCasualJob ?
                <div className="w-3/4 mx-auto text-gray-700 cursor-default font-light py-2">
                    <div className={"border border-red-700 px-1.5 inline-block w-auto rounded-md " + (casualConditionsNotAccepted ? "border-opacity-100" : "border-opacity-0")}>
                        <input className="mr-2" type="checkbox" checked={casualConditionsAccepted} onChange={toggleCasualCheck}></input>
                        I have read and accepted the<span> </span>
                        <span className="text-blue-800 underline cursor-pointer" onClick={() => setShowCasualConditions(true)}>conditions</span>
                        <span> </span>
                        for casual hire.
                    </div>
                    {casualConditionsNotAccepted ?
                        <div className="text-red-700 text-sm px-3 flex items-center">
                            <svg className="h-4 w-4 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                            </svg>
                            <div className="pl-1 inline">
                                Conditions need to be accepted to continue.
                            </div>
                        </div> : <div></div>
                    }

                </div>
                : <div></div>
            }
            {
                containsInorganicJob ? 
                <div className="w-3/4 mx-auto text-gray-700 cursor-default font-light py-2">
                <div className={"border border-red-700 px-1.5 inline-block w-auto rounded-md " + (inorganicConditionsNotAccepted ? "border-opacity-100" : "border-opacity-0")}>
                    <input className="mr-2" type="checkbox" checked={inorganicConditionsAccepted} onChange={toggleInorganicCheck}></input>
                    I have read and accepted the <span> </span>
                    <span className="text-blue-800 underline cursor-pointer" onClick={() => setShowInorganicConditions(true)}>conditions</span>
                    <span> </span>
                    of inorganic collection.
                </div>
                {inorganicConditionsNotAccepted ?
                    <div className="text-red-700 text-sm px-3 flex items-center">
                        <svg className="h-4 w-4 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                        <div className="pl-1 inline">
                            Conditions need to be accepted to continue.
                    </div>
                    </div> : <div></div>
                }
                </div>
                : <div></div>
            }
            { containsSkipBinJob ?
                <div className="w-3/4 mx-auto text-gray-700 cursor-default font-light py-2">
                    <div className={"border border-red-700 px-1.5 inline-block w-auto rounded-md " + (skipBinConditionsNotAccepted ? "border-opacity-100" : "border-opacity-0")}>
                        <input className="mr-2" type="checkbox" checked={skipBinConditionsAccepted} onChange={toggleSkipBinCheck}></input>
                        I have read and accepted the <span> </span>
                        <span className="text-blue-800 underline cursor-pointer" onClick={() => setShowSkipBinConditions(true)}>conditions</span>
                        <span> </span>
                        of skip bin hire.
                    </div>
                    {skipBinConditionsNotAccepted ?
                        <div className="text-red-700 text-sm px-3 flex items-center">
                            <svg className="h-4 w-4 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                            </svg>
                            <div className="pl-1 inline">
                                Conditions need to be accepted to continue.
                        </div>
                        </div> : <div></div>
                    }
                </div>
                : <div></div>
            }
            <QuoteAcceptanceControl acceptCallback={handleAccept} declineCallback={handleDecline} ></QuoteAcceptanceControl>
            {/* <div className="h-16 relative w-3/4 mx-auto pt-2">
                {accepted || cancelled ?
                    <div className="text-center text-gray-700">
                        {cancelled ? <div>This booking has been cancelled</div> : <div>
                        {paid ? <div>This booking has been paid</div> : <div>This booking has been accepted</div>}</div>}
                    </div> :
                    <div className="absolute right-0">
                        <button className="btn-blue btn-blue-active" onClick={handleAccept}>
                            Accept
                            </button>
                        <button className="btn-blue btn-blue-active" onClick={handleDecline}>
                            Decline
                            </button>
                    </div>
                }
            </div> */}
            <div className="w-full text-center text-gray-400 text-sm mt-10 mb-20">
                <span className="text-center">0800 36 77 35 | info@rubbishdirect.co.nz | www.rubbishdirect.co.nz</span>
            </div>

            <Modal
                height="h-screen"
                width="w-3/5"
                show={showSkipBinConditions}
                closeModal={() => setShowSkipBinConditions(false)}
            >
                <SkipBinConditions></SkipBinConditions>
            </Modal>
            <Modal
                height="h-screen"
                width="w-3/5"
                show={showInorganicConditions}
                closeModal={() => setShowInorganicConditions(false)}
            >
                <InorganicConditions></InorganicConditions>
            </Modal>
            <Modal
                height="h-screen"
                width="w-3/5"
                show={showCasualConditions}
                closeModal={() => setShowCasualConditions(false)}
            >
                <CasualConditions></CasualConditions>
            </Modal>
            <Modal
                height="h-screen"
                width="w-screen"
                show={showAcceptModal}
                closeModal={() => setShowAcceptModal(false)}
            >
                <AcceptanceDialog closeCallback={() => setShowAcceptModal(false)}></AcceptanceDialog>
            </Modal>
            <Modal
                height="h-screen"
                width="w-screen"
                show={showDeclineModal}
                closeModal={() => setShowDeclineModal(false)}
            >
                <DeclineMessage></DeclineMessage>
            </Modal>
        </div>
    )
}

export default QuotePanel;