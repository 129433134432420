import { applyMiddleware, createStore, compose } from 'redux';
// import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import rootReducer from '../reducers';
import rootEpic from '../epics';

// const composeEnhancers = composeWithDevTools({});

const composeEnhancers =
  (process.env.NODE_ENV.trim() !== 'production' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const epicMiddleWare = createEpicMiddleware();

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(
        epicMiddleWare
    )
));

epicMiddleWare.run(rootEpic);

export default store;