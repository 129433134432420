import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router';
import { acceptBookingWithPaymentVerification, loadBooking } from "../../actions/booking-actions";

const VerifyPayment = ({ closeCallback }) => {

    const processing = useSelector((state) => {
        return state.bookingReducer.processingAcceptWithVerification
    })

    const booking = useSelector(state => {
        return state.bookingReducer.booking;
    })

    const processingSuccess = useSelector((state) => {
        return state.bookingReducer.processingAcceptWithVerificationSuccess
    })

    const processingError = useSelector((state) => {
        return state.bookingReducer.processingAcceptWithVerificationError
    })

    const dispatch = useDispatch();

    let { auth, uuid } = useParams();


    const handleAccept = () => {
        dispatch(acceptBookingWithPaymentVerification({id: booking.id, auth: auth}))
    }

    const closeAfterSuccess = () => {
        closeCallback();
        dispatch(loadBooking({uuid: uuid, auth: auth}))
    }

    if (processing) {
        return (
            <div>
                <div className="px-4 py-2 m-2 text-sm text-center text-blue-600">Processing booking</div>
                <div className="w-10 h-30 mx-auto m-2">
                    <svg className="animate-spin h-10 w-10 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            </div>
        )
    }

    if (processingError) {
        return (
            <div>
                <div className="text-red-600 flex flex-col items-center">
                    <svg className="h-10 w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <div>
                        An error occured
                    </div>
                </div>
            </div>
        )
    }

    if (processingSuccess) {
        return (
            <div>
                <div className="text-green-600 flex flex-col items-center text-center">
                    <div>
                        Booking has been accepted. <br/>An email will be sent to you shortly
                    </div>
                    <button className="btn-blue btn-blue-active inline" onClick={closeAfterSuccess}>Close</button>
                </div>
            </div>
        )
    }

    return (
        <div className="h-30">
            <div className="text-center">Click confirm to accept the Booking. <br/> An invoice and payment instructions will be emailed to you</div>
            <div className="w-48 mx-auto pt-3">
                <button className="btn-blue btn-blue-active inline" onClick={handleAccept}>Confirm</button>
                <button className="btn-blue btn-blue-active inline" onClick={closeCallback}>Close</button>
            </div>
        </div>
    )
}

export default VerifyPayment;